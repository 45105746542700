import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@aq_mobile/ui-kit';
import { PlusIcon } from '@aq_mobile/ui-kit/icons';
import { MARGINS } from '@aq_mobile/ui-kit/utils';
import { Form } from 'antd';

import { IMEIRange } from './IMEIRange';
import { IMEIRangesProps } from './IMEIRanges.types';
import { checkRangesIntersection } from './IMEIRanges.utils';

/**
 * Компонент для редактирования диапазонов IMEI.
 */
function IMEIRanges({ name, label }: IMEIRangesProps) {
  const { t } = useTranslation();
  const rangesLabel = label ?? t('components.IMEIRanges.label');
  // Используется для обозначения пересекающихся диапазонов.
  const [clashedRanges, setClashedRanges] = useState<Array<number>>([]);

  return (
    <>
      <Typography.Heading5
        style={{
          marginBlockStart: MARGINS.section * 2,
          marginBlockEnd: MARGINS.section,
        }}
      >
        {rangesLabel}
      </Typography.Heading5>
      <Form.List
        name={name}
        rules={[
          {
            validator(_, value) {
              const clashedIndexes = checkRangesIntersection(value);
              setClashedRanges(clashedIndexes);

              if (!clashedIndexes.length) {
                return Promise.resolve();
              }

              const errorMessage = t('components.IMEIRanges.rangesIntersect', {
                ranges: clashedIndexes.map((i) => i + 1).join(', '),
              });

              return Promise.reject(errorMessage);
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            <div style={{ marginBlockEnd: MARGINS.subtitle }}>
              {fields.map((range, index) => (
                <IMEIRange
                  formItemName={name}
                  startName={[range.name, 'start']}
                  endName={[range.name, 'end']}
                  number={index + 1}
                  key={range.key}
                  onDelete={() => remove(range.name)}
                  hasError={clashedRanges.includes(index)}
                />
              ))}
              <Form.Item>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </div>
            <div>
              <Button
                type="dashed"
                icon={<PlusIcon style={{ fontSize: 9 }} />}
                style={{ width: '100%' }}
                onClick={() =>
                  add({
                    start: '',
                    end: '',
                  })
                }
              >
                {t('components.IMEIRanges.addLabel')}
              </Button>
            </div>
          </>
        )}
      </Form.List>
    </>
  );
}

export default memo(IMEIRanges);
