import getAntdLocale from './getAntdLocale';

/**
 * Возвращает локализацию для таблицы, в зависимости от переданного кода языка.
 * По умолчанию возвращает undefined, что означает English
 */
function getTableLocale(locale: string) {
  return getAntdLocale(locale).Table;
}

export default getTableLocale;
