import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ServerError, useGetGroupsQuery } from '@/features/api';
import { getServerErrorDescriptions } from '@/features/api/api.utils';
import { NotificationContext } from '@/features/notifications';

/**
 * Хук для управления списка групп IMEI
 */
function useGroups() {
  const { t } = useTranslation();
  const notificationContext = useContext(NotificationContext);
  const {
    isLoading: isGroupsLoading,
    data,
    error: groupsError,
  } = useGetGroupsQuery();

  const groups = data || [];

  useEffect(() => {
    if (!groupsError) {
      return;
    }

    const errorMessages = getServerErrorDescriptions(
      groupsError as unknown as ServerError,
    );

    notificationContext.showError(
      t('features.groups.getGroupsError'),
      errorMessages,
    );
    console.error(t('features.groups.getGroupsError'), groupsError);
  }, [groupsError, notificationContext, t]);

  return {
    isGroupsLoading,
    groups,
    groupsError,
  };
}

export default useGroups;
