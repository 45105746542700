import { TFunction } from 'i18next';

export type UpdateGroupError = {
  name?: Array<string> | string;
  imei_list?: {
    [key: string]: Array<string>;
  };
  ranges?: Array<RangeError>;
};

type RangeError = {
  range: Array<string>;
};

/**
 * Вынимает ошибки обновления группы IMEI.
 */
export function parseUpdateGroupErrors(
  errors: any,
  t: TFunction<'translation', undefined>,
) {
  const { imei_list, ranges, ...unknownErrors } = errors as UpdateGroupError;

  let imeiListError: Array<[string, string[]]> = imei_list
    ? Object.entries(imei_list)
    : [];
  let rangesError: Array<RangeError> =
    ranges && Array.isArray(ranges) ? ranges : [];

  const imeiListErrorDescription = parseIMEIListErrors(imeiListError, t);
  const rangesErrorDescription = parseRangesError(rangesError, t);

  return {
    imeiListErrorDescription,
    rangesErrorDescription,
    unknownErrors,
  };
}

function parseIMEIListErrors(
  entries: Array<[string, string[]]>,
  t: TFunction<'translation', undefined>,
): Array<string> {
  const imeiErrors = entries.map(([index, descriptions]) => {
    return descriptions.map((description) => {
      if (description.startsWith('Ensure this value is greater than')) {
        return t('features.groups.imeiHaveToBeGreater', {
          num: Number(index) + 1,
        });
      }

      return t('features.groups.imeiError', {
        num: Number(index) + 1,
      });
    });
  });

  return imeiErrors.flat();
}

function parseRangesError(
  errors: Array<RangeError>,
  t: TFunction<'translation', undefined>,
): Array<string> {
  const rangeErrors = errors.map((error) => {
    return error.range.map((errorDescription) => {
      if (
        errorDescription.startsWith(
          `Ensure that the lower bound of the range is not less than`,
        )
      ) {
        return t('features.groups.rangeStartMustBeGreater');
      }

      return t('features.groups.rangeError');
    });
  });

  return rangeErrors.flat();
}
