import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, ContentCard } from '@aq_mobile/ui-kit';
import { ArrowDownToLineIcon } from '@aq_mobile/ui-kit/icons';
import { Form, Input, Space, Spin } from 'antd';

import { ErrorList } from '@/components';
import { ErrorRecord } from '@/components/ErrorList/ErrorList.types';
import {
  errorsToErrorsList,
  shouldBeEmptyValidatorFactory,
} from '@/components/ErrorList/ErrorList.utils';
import { IMEIImportModal } from '@/components/IMEIImportModal';
import { IMEIImportResult } from '@/components/IMEIImportModal/IMEIImportModal.types';
import { IMEIInput } from '@/components/IMEIInput';
import IMEIRanges from '@/components/IMEIRanges/IMEIRanges';
import { IMEIStringRange } from '@/components/IMEIRanges/IMEIRanges.types';
import {
  IMEINumberRangesToStringRanges,
  IMEIRangeToString,
  IMEIsStringRangeToIMEIRange,
  IMEIStringRangeToIMEIRange,
} from '@/components/IMEIRanges/IMEIRanges.utils';
import { GROUP_NAME_MAX_LENGTH } from '@/constants';
import { useGroup, useGroupEdit } from '@/features/groups';
import {
  IMEINumberToString,
  IMEIsToString,
  isIMEIInRange,
  stringToIMEIs,
} from '@/features/imei';
import { NotificationContext } from '@/features/notifications';
import getUnique from '@/utils/getUnique';

type IMEIForm = {
  name: string;
  imei: string;
  range: Array<IMEIStringRange>;
  errors: Array<ErrorRecord>;
};

/**
 * Форма редактирования группы IMEI.
 */
function Group() {
  const { t } = useTranslation();
  const { id: groupIdParam } = useParams();
  const id = Number(groupIdParam);
  const navigate = useNavigate();
  const location = useLocation();
  const { group, isGroupLoading } = useGroup(id);
  const { updateGroup, isGroupUpdating } = useGroupEdit();
  const notificationContext = useContext(NotificationContext);
  const [form] = Form.useForm<IMEIForm>();
  const [isImportOpen, setIsImportOpen] = useState(false);
  const [hasImportErrors, setHasImportErrors] = useState(false);
  const handleSaveClick = useCallback(() => {
    form.submit();
  }, [form]);

  useEffect(() => {
    // Проставляем новые значения группы IMEI, как только они получены с сервера.
    if (!group) {
      return;
    }

    const { name, imei_list, ranges } = group;
    const imei = Array.isArray(imei_list) ? IMEIsToString(imei_list) : '';
    const range = Array.isArray(ranges)
      ? IMEINumberRangesToStringRanges(ranges)
      : [];

    form.setFieldsValue({
      name,
      imei,
      range,
    });
  }, [form, group]);

  const handleImportOpen = useCallback(() => {
    form
      .validateFields()
      .then(() => {
        setIsImportOpen(true);
      })
      .catch((e) => {
        notificationContext.showError(
          t('routes.Group.importImpossibleErrorTitle'),
          t('routes.Group.importImpossibleErrorDescription'),
        );
      });
  }, [form, notificationContext, t]);

  const isFormPending = isGroupLoading || isGroupUpdating;

  const importButtonTemplate = useMemo(
    () => (
      <Button
        type="link"
        icon={
          <ArrowDownToLineIcon
            style={{
              width: '1.23em',
              height: '1.23em',
            }}
          />
        }
        onClick={handleImportOpen}
        disabled={isFormPending}
      >
        Импорт
      </Button>
    ),
    [handleImportOpen, isFormPending],
  );

  /**
   * Обработчик изменения списка ошибок импорта.
   * Используется для скрытия элемента списка, вызывается
   * каждый раз, когда значение компонента изменяется.
   */
  const handleImportErrorsChanged = useCallback(() => {
    const importErrors = form.getFieldValue('errors') as
      | Array<ErrorRecord>
      | undefined;

    if (!importErrors) {
      setHasImportErrors(false);
      return;
    }

    setHasImportErrors(true);
  }, [form]);

  /**
   *  Обработчик результатов импорта.
   * Собирает ошибки импорта и вставляет результирующие IMEI в список IMEI.
   * Для того, чтобы импорт сработал корректно, форма должна быть валидна.
   */
  const handleImport = useCallback(
    ({ imei: importIMEI, errors: importErrors }: IMEIImportResult) => {
      setIsImportOpen(false);

      // Массив IMEI номеров включающий, список, указанный на форме и импортированные
      const correctIMEIs: Array<number> = [];
      // Список ошибок импорта
      const errors: Array<string> = [];

      if (importErrors.length) {
        importErrors.forEach((error) => {
          errors.push(t('routes.Group.cannotParseValue', { error }));
        });
        setHasImportErrors(true);
      }

      // Если кроме ошибок импорта больше ничего нет.
      if (!importIMEI.length && errors.length) {
        form.setFieldValue('errors', errorsToErrorsList(errors));
        notificationContext.showError(
          t('routes.Group.importErrorTitle'),
          t('routes.Group.importErrorDescription', {
            count: errors.length,
          }),
        );
        return;
      }

      // Если нет ошибок импорта и распознанных номеров.
      if (!importIMEI.length && !errors.length) {
        notificationContext.showWarning(
          t('routes.Group.importComplete'),
          t('routes.Group.importNothingFoundWarning'),
        );
        return;
      }

      const formIMEIsString = form.getFieldValue('imei');
      const formIMEIList = stringToIMEIs(
        formIMEIsString,
        t('features.imei.imeiParseError'),
      );
      const formIMEIStringRanges = form.getFieldValue(
        'range',
      ) as Array<IMEIStringRange>;
      const ranges = formIMEIStringRanges.map((range) =>
        IMEIStringRangeToIMEIRange(range, t),
      );

      // Определяем возможные дубликаты, среди импортированных номеров.
      const { unique: uniqueIMEIs, duplicates } = getUnique([
        ...formIMEIList,
        ...importIMEI,
      ]);

      // Дубликаты добавляются в список ошибок.
      duplicates.forEach((duplicate) => {
        const message = t('routes.Group.isAlreadyInList', {
          num: IMEINumberToString(duplicate),
        });
        errors.push(message);
      });

      // Проверяем попадание номеров в имеющиеся диапазоны
      uniqueIMEIs.forEach((imei) => {
        let isIncludedInRange = false;
        ranges.forEach((range) => {
          if (!isIMEIInRange(imei, range)) {
            return;
          }

          const message = t('routes.Group.isInRange', {
            num: IMEINumberToString(imei),
            range: IMEIRangeToString(range),
          });

          errors.push(message);
          isIncludedInRange = true;
        });

        if (!isIncludedInRange) {
          correctIMEIs.push(imei);
        }
      });

      form.setFieldValue('imei', IMEIsToString(correctIMEIs));
      form.setFieldValue('errors', errorsToErrorsList(errors));

      if (errors.length) {
        setHasImportErrors(true);
        const message = t('routes.Group.importCompleteWithErrorDescription', {
          count: importIMEI.length,
          errorsCount: errors.length,
        });

        notificationContext.showWarning(
          t('routes.Group.importCompleteWithError'),
          message,
        );
        return;
      }

      notificationContext.showSuccess(
        t('routes.Group.importCompleteMessage'),
        t('routes.Group.importCompleteDescription', {
          count: importIMEI.length,
        }),
      );
      return;
    },
    [form, notificationContext, t],
  );

  const handleImportCancel = useCallback(() => setIsImportOpen(false), []);

  /**
   * Обработчик поведения нажатия на кнопку "Назад".
   */
  const handleBack = useCallback(() => {
    // Переменная location.state имеет значение, если переход на форму
    // был осуществлен из раздела поиска.
    // Значение state-а это строка поиска, которую нужно будет восстановить.
    if (location.state) {
      navigate('/search', { state: location.state });
      return;
    }

    //
    navigate('/groups');
  }, [location.state, navigate]);

  const handleSubmit = useCallback(
    async (values: IMEIForm) => {
      const name = values.name;
      const imei_list = stringToIMEIs(
        values.imei,
        t('features.imei.imeiParseError'),
      );
      const ranges = IMEIsStringRangeToIMEIRange(values.range, t);

      await updateGroup({
        id,
        name,
        imei_list,
        ranges,
      }).unwrap();

      notificationContext.showSuccess(t('routes.Group.changesSaved'));
    },
    [id, notificationContext, t, updateGroup],
  );

  return (
    <>
      <ContentCard>
        <ContentCard.Header
          title={t('routes.Group.title')}
          buttons={importButtonTemplate}
        />
        <ContentCard.Body>
          <Spin spinning={isFormPending}>
            <Form
              form={form}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              colon={false}
              labelAlign="left"
              onFinish={handleSubmit}
            >
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: t('routes.Group.nameRequired'),
                  },
                  {
                    max: GROUP_NAME_MAX_LENGTH,
                    message: t('routes.Group.maximumLengthExceed', {
                      length: GROUP_NAME_MAX_LENGTH,
                    }),
                  },
                ]}
                label={t('routes.Group.nameLabel')}
              >
                <Input />
              </Form.Item>
              <IMEIInput name="imei" rangesInputName="range" />
              <IMEIRanges name="range" />
              <Form.Item
                wrapperCol={{ span: 24 }}
                name="errors"
                rules={[
                  () => ({
                    validator: shouldBeEmptyValidatorFactory(
                      t('components.ErrorList.requireToCleanErrors'),
                    ),
                  }),
                ]}
                hidden={!hasImportErrors}
              >
                <ErrorList onChange={handleImportErrorsChanged} />
              </Form.Item>
            </Form>
          </Spin>
        </ContentCard.Body>
        <ContentCard.Footer>
          <Space>
            <Button onClick={handleBack}>{t('routes.Group.return')}</Button>
            <Button
              type="primary"
              onClick={handleSaveClick}
              disabled={isFormPending}
            >
              {t('routes.Group.save')}
            </Button>
          </Space>
        </ContentCard.Footer>
      </ContentCard>
      <IMEIImportModal
        open={isImportOpen}
        onOk={handleImport}
        onCancel={handleImportCancel}
      />
    </>
  );
}

export default Group;
