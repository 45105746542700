import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ServerError,
  useCreateGroupMutation,
  useDeleteGroupMutation,
  useSearchGroupMutation,
  useUpdateGroupMutation,
} from '@/features/api';
import { getServerErrorDescriptions } from '@/features/api/api.utils';
import { NotificationContext } from '@/features/notifications';

// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths
import { parseUpdateGroupErrors } from '../groups.utils';

/**
 * Методы для взаимодействия с группой IMEI
 */
function useGroupEdit() {
  const { t } = useTranslation();
  const notificationContext = useContext(NotificationContext);
  const [
    createGroup,
    {
      isLoading: isGroupCreating,
      error: createGroupError,
      isSuccess: isCreateGroupSuccess,
    },
  ] = useCreateGroupMutation();

  const [
    updateGroup,
    {
      isLoading: isGroupUpdating,
      error: updateGroupError,
      isSuccess: isUpdateGroupSuccess,
    },
  ] = useUpdateGroupMutation();

  const [
    deleteGroup,
    {
      isLoading: isGroupDeleting,
      error: deleteGroupError,
      isSuccess: isDeleteGroupSuccess,
    },
  ] = useDeleteGroupMutation();

  const [
    searchGroup,
    {
      isLoading: isGroupSearching,
      error: searchGroupError,
      isSuccess: isSearchGroupSuccess,
    },
  ] = useSearchGroupMutation();

  // Обработка ошибки создания группы
  useEffect(() => {
    if (!createGroupError) {
      return;
    }

    const messages = getServerErrorDescriptions(
      createGroupError as unknown as ServerError,
    );

    notificationContext.showError(t('features.groups.createError'), messages);
    console.error(t('features.groups.createError'), createGroupError);
  }, [createGroupError, notificationContext, t]);

  // Обработка ошибки обновления группы
  useEffect(() => {
    if (!updateGroupError) {
      return;
    }

    if (!('data' in updateGroupError)) {
      notificationContext.showError(t('features.groups.updateUnknownError'));

      console.error(t('features.groups.updateUnknownError'), updateGroupError);

      return;
    }

    const { imeiListErrorDescription, rangesErrorDescription, unknownErrors } =
      parseUpdateGroupErrors(updateGroupError.data, t);

    const unknownErrorMessages =
      getServerErrorDescriptions(unknownErrors as unknown as ServerError) ?? [];

    const combinedErrors = [
      ...imeiListErrorDescription,
      ...rangesErrorDescription,
      ...unknownErrorMessages,
    ];

    notificationContext.showError(
      t('features.groups.updateError'),
      combinedErrors,
    );
    console.error(t('features.groups.updateError'), updateGroupError);
  }, [updateGroupError, notificationContext, t]);

  // Обработка ошибки удаления группы
  useEffect(() => {
    if (!deleteGroupError) {
      return;
    }

    const messages = getServerErrorDescriptions(
      deleteGroupError as unknown as ServerError,
    );

    notificationContext.showError(t('features.groups.deleteError'), messages);
    console.error(t('features.groups.deleteError'), deleteGroupError);
  }, [deleteGroupError, notificationContext, t]);

  // Обработка ошибки поиска группы
  useEffect(() => {
    if (!searchGroupError) {
      return;
    }

    const messages = getServerErrorDescriptions(
      searchGroupError as unknown as ServerError,
    );

    notificationContext.showError(t('features.groups.searchError'), messages);
    console.error(t('features.groups.searchError'), searchGroupError);
  }, [searchGroupError, notificationContext, t]);

  return {
    createGroup,
    createGroupError,
    isGroupCreating,
    isCreateGroupSuccess,
    deleteGroup,
    deleteGroupError,
    isGroupDeleting,
    isDeleteGroupSuccess,
    updateGroup,
    isGroupUpdating,
    updateGroupError,
    isUpdateGroupSuccess,
    searchGroup,
    searchGroupError,
    isGroupSearching,
    isSearchGroupSuccess,
  };
}

export default useGroupEdit;
