import {
  BaseQueryApi,
  QueryReturnValue,
} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query';

import { RootState } from '@/features/store';
import i18n from '@/i18n';

import {
  ErrorRequestData,
  NO_ACCOUNT_ERROR_MESSAGE as INVALID_TOKEN_ERROR_MESSAGE,
} from './baseQuery.types';

/**
 * Возвращает true, если произошла ошибка, связанная с необходимостью обновить токен.
 * Ошибка имеет код 401 и не связана с некорректным логином.
 * @param result результат запроса
 */
export function checkIsTokenRefreshRequired(
  result: QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>,
): boolean {
  const { error } = result;

  if (!error) {
    return false;
  }

  const isStaleToken = Boolean(
    (error.data as ErrorRequestData)?.detail === INVALID_TOKEN_ERROR_MESSAGE,
  );

  return isStaleToken;
}

/**
 * Возвращает код локализации, в формате, принимаемым сервером.
 * @param language код локализации
 */
function getServerLocale(language: string) {
  switch (language) {
    case 'en':
    case 'en-US':
      return 'en-US';
    case 'ru':
    case 'ru-RU':
    default:
      return 'ru';
  }
}

/**
 * Возвращает аргументы с хедером авторизации.
 */
export function argsWithHeaders(args: string | FetchArgs, api: BaseQueryApi) {
  const { accessToken } = (api.getState() as RootState).user;
  const locale = getServerLocale(i18n.language);

  const headers = accessToken
    ? {
        Authorization: 'Bearer ' + accessToken,
        'Accept-Language': locale,
      }
    : {};

  const patchedArgs =
    typeof args === 'string'
      ? args
      : {
          ...args,
          headers: {
            ...args.headers,
            ...headers,
          },
        };

  return patchedArgs;
}
