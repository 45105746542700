import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ExclamationMarkCircleOutlineIcon } from '@aq_mobile/ui-kit/icons';
import { Form, Input } from 'antd';

import { IMEIStringRange } from '@/components/IMEIRanges/IMEIRanges.types';
import { IMEIStringRangeToIMEIRange } from '@/components/IMEIRanges/IMEIRanges.utils';
import {
  IMEINumberToString,
  IMEIRange,
  isIMEIInRange,
  StringToIMEIError,
  stringToIMEIs,
} from '@/features/imei';
import getDuplicates from '@/utils/getDuplicates';

import { IMEIInputProps } from './IMEIInput.types';

/**
 * Компонент для редактирования диапазонов IMEI.
 */
function IMEIInput({ name, label, tooltip, rangesInputName }: IMEIInputProps) {
  const { t } = useTranslation();
  const fieldLabel = label ?? t('components.IMEIInput.label');
  const fieldTooltip = tooltip ?? t('components.IMEIInput.tooltip');
  const dependentFields = rangesInputName ? [rangesInputName] : undefined;

  return (
    <Form.Item
      label={fieldLabel}
      tooltip={{
        title: fieldTooltip,
        icon: <ExclamationMarkCircleOutlineIcon />,
      }}
      name={name}
      dependencies={dependentFields}
      validateTrigger={['onBlur', 'onChange']}
      rules={[
        ({ getFieldValue }) => {
          return {
            validator(_, IMEIstr) {
              // Если не указан компонент с диапазонами.
              if (!rangesInputName) {
                return Promise.resolve();
              }

              const ranges = getFieldValue(
                rangesInputName,
              ) as Array<IMEIStringRange>;

              // Если диапазоны отсутствуют, то проверять нечего.
              if (!ranges || !ranges.length) {
                return Promise.resolve();
              }

              let IMEIList: Array<number> = [];
              // Если список IMEI указан с ошибкой, то на это срабатывают другие валидаторы.
              try {
                IMEIList = stringToIMEIs(
                  IMEIstr,
                  t('features.imei.imeiParseError'),
                );
              } catch {
                return Promise.resolve();
              }

              const numericRanges: Array<IMEIRange> = [];

              ranges.forEach((range) => {
                // Пропускаем диапазоны с ошибками. Т.к. на них срабатывают другие валидаторы.
                try {
                  const numericRange = IMEIStringRangeToIMEIRange(range, t);
                  numericRanges.push(numericRange);
                } catch (error) {}
              });

              // Проверяем, что IMEI не входят ни в какой массив.
              const errorMessages: Array<string> = [];

              numericRanges.forEach((range) => {
                IMEIList.forEach((imei) => {
                  if (!isIMEIInRange(imei, range)) {
                    return;
                  }

                  const inRange = t('components.IMEIInput.isAlreadyInRange', {
                    num: IMEINumberToString(imei),
                    start: IMEINumberToString(range.range.lower),
                    end: IMEINumberToString(range.range.upper),
                  });

                  errorMessages.push(inRange);
                });
              });

              if (errorMessages.length) {
                return Promise.reject(errorMessages.join('; '));
              }

              return Promise.resolve();
            },
          };
        },
        {
          validator(_, value) {
            try {
              const IMEIs = stringToIMEIs(
                value,
                t('features.imei.imeiParseError'),
              );
              const duplicates = getDuplicates(IMEIs);

              if (duplicates.length) {
                const duplicateIMEIs = duplicates.map(IMEINumberToString);
                const errorMessage = t(
                  'components.IMEIInput.multipleOccurrencesError',
                  {
                    numbers: duplicateIMEIs.join(', '),
                  },
                );
                return Promise.reject(errorMessage);
              }

              return Promise.resolve();
            } catch (error) {
              if (error instanceof StringToIMEIError === false) {
                return;
              }

              const { lengthErrors, formatErrors } = error;

              let errorMessage = '';

              if (lengthErrors.length) {
                errorMessage = t('components.IMEIInput.incorrectLength', {
                  numbers: error.lengthErrors.join(', '),
                });
              }

              if (formatErrors.length) {
                errorMessage = errorMessage.length
                  ? `${errorMessage}. `
                  : errorMessage;
                errorMessage += t('components.IMEIInput.incorrectFormat', {
                  numbers: error.formatErrors.join(', '),
                });
              }

              return Promise.reject(errorMessage);
            }
          },
        },
      ]}
    >
      <Input.TextArea
        rows={2}
        placeholder={t('components.IMEIInput.numbersList')}
      />
    </Form.Item>
  );
}

export default memo(IMEIInput);
