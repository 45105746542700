import { Locale } from 'antd/lib/locale';
import localeEN from 'antd/locale/en_US';
import localeRU from 'antd/locale/ru_RU';

/**
 * Возвращает локализацию для встроенных компонентов Antd
 */
function getAntdLocale(locale: string): Locale {
  switch (locale) {
    case 'ru':
    case 'ru-RU':
      return localeRU;
    default:
      return localeEN;
  }
}

export default getAntdLocale;
