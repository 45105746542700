import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ServerError, useGetGroupQuery } from '@/features/api';
import { getServerErrorDescriptions } from '@/features/api/api.utils';
import { NotificationContext } from '@/features/notifications';

/**
 * Хук, для получения группы IMEI по идентификатору.
 */
function useGroup(id: number | undefined) {
  const { t } = useTranslation();
  const notificationContext = useContext(NotificationContext);
  const {
    data: group,
    isLoading: isGroupLoading,
    error: groupError,
    isSuccess: isGroupSuccess,
  } = useGetGroupQuery(id || 0, {
    skip: Boolean(!id),
  });

  useEffect(() => {
    if (!groupError) {
      return;
    }

    const messages = getServerErrorDescriptions(
      groupError as unknown as ServerError,
    );

    notificationContext.showError(t('features.groups.getGroupError'), messages);
    console.error(t('features.groups.getGroupError'), { groupError, id });
  }, [groupError, id, notificationContext, t]);

  return {
    group,
    isGroupLoading,
    groupError,
    isGroupSuccess,
  };
}

export default useGroup;
