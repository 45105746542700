import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BooksIcon, MagnifyingGlassIcon } from '@aq_mobile/ui-kit/icons';

import { MenuItem } from '@/components';

/**
 * Возвращает доступные пункты меню системы.
 */
function useMenuItems() {
  const { t } = useTranslation();

  const menuItems: Array<MenuItem> = useMemo(
    () => [
      {
        key: '/groups',
        icon: <BooksIcon />,
        path: '/groups',
        label: t('features.menuItems.groups'),
      },
      {
        key: '/search',
        icon: <MagnifyingGlassIcon />,
        path: '/search',
        label: t('features.menuItems.search'),
      },
    ],
    [t],
  );

  return menuItems;
}

export default useMenuItems;
