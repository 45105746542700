import { RuleObject } from 'antd/es/form';

import { ErrorRecord } from './ErrorList.types';

export function shouldBeEmptyValidatorFactory(errorDescription: string) {
  return function shouldBeEmptyValidator(_: RuleObject, value: any) {
    if (Array.isArray(value) && value.length > 0) {
      return Promise.reject(errorDescription);
    }

    return Promise.resolve();
  };
}

/**
 * Конвертирует массив в описанием ошибок в массив ошибок, поддерживаемых компонентом.
 */
export function errorsToErrorsList(errors: Array<string>): Array<ErrorRecord> {
  return errors.map((error) => ({
    id: crypto.randomUUID(),
    description: error,
  }));
}
