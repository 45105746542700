import isDigitsOnly from '@/utils/isDigitsOnly';

import { IMEI_LENGTH, IMEIRange } from './imei.types';

/**
 * Возвращает число, конвертированное в строковое представление IMEI
 * с лидирующими нулями.
 */
export function IMEINumberToString(number: number) {
  return number.toString().padStart(IMEI_LENGTH, '0');
}

/**
 * Возвращает числовое представление IMEI, конвертированное из строкового представления.
 */
export function IMEIStringToNumber(value: string) {
  return Number(value);
}

/**
 * Возвращает true, если указанный IMEI входит в диапазон
 */
export function isIMEIInRange(imei: number, range: IMEIRange) {
  return imei >= range.range.lower && imei <= range.range.upper;
}

/**
 * Ошибка разбора IMEI
 */
export class StringToIMEIError extends Error {
  /**
   * Список строковых значений, которые не были распознаны, как IMEI
   */
  formatErrors: Array<string>;
  /**
   * Список IMEI с некорректно указанной длиной
   */
  lengthErrors: Array<string>;
  /**
   * Список корректно разобранных IMEI
   */
  IMEIs: Array<number>;

  constructor(
    message: string,
    formatErrors: Array<string>,
    lengthErrors: Array<string>,
    IMEIs: Array<number>,
  ) {
    super(message);
    this.name = 'StringToIMEIError';
    this.formatErrors = formatErrors;
    this.IMEIs = IMEIs;
    this.lengthErrors = lengthErrors;
  }
}

export class ParseIMEIFormatError extends Error {}

export class ParseIMEILengthError extends Error {}

export type StringToIMEIErrorType = typeof StringToIMEIError;

/**
 * Преобразует массив IMEI номеров в текстовое представление.
 */
export function IMEIsToString(imeiList: Array<number>): string {
  return imeiList.map(IMEINumberToString).join(', ');
}

/**
 * Парсит номер IMEI из строки.
 * Выбрасывает ошибку, если переданная строка не является IMEI номером.
 * @returns числовое представление IMEI
 */
export function parseIMEI(str: string | null): number {
  if (!str || str.length !== IMEI_LENGTH) {
    throw new ParseIMEILengthError(
      `IMEI parse error: incorrect length of input string: ${str}`,
    );
  }

  if (!isDigitsOnly(str)) {
    throw new ParseIMEIFormatError(
      `IMEI parse error: string should contain only numbers: ${str}`,
    );
  }

  const imei = Number.parseInt(str);

  if (Number.isNaN(imei)) {
    throw new ParseIMEIFormatError(
      `IMEI parse error: incorrect format: ${imei}`,
    );
  }

  return imei;
}

/**
 * Принимает строку, содержащую номера IMEI, разделенные запятой.
 * Возвращает массив номеров IMEI.
 * Если при разборе произошла ошибка, будет выброшено исключение.
 *
 * @throws StringToIMEIErrorType - если произошли ошибки при преобразовании
 *
 * {
 *
 *  formatErrors, // Массив строковых значений, не являющихся IMEI,
 *
 *  lengthErrors, // Массив разобранных IMEI некорректной длины.
 *
 *  IMEIs, // Массив разобранных IMEI.
 *
 * }
 *
 */
export function stringToIMEIs(
  imeiString: string,
  errorMessage: string,
): Array<number> {
  const IMEIs: Array<number> = [];
  const formatErrors: Array<string> = [];
  const lengthErrors: Array<string> = [];

  imeiString
    .split(',')
    .map((value) => value.trim())
    .forEach((imeiString) => {
      try {
        // Пропускаем пустые значения, которые могут появиться на конце строки
        // например: '0000000000000001, '
        if (!imeiString.length) {
          return;
        }

        const imei = parseIMEI(imeiString);
        IMEIs.push(imei);
      } catch (e: unknown) {
        if (e instanceof ParseIMEIFormatError) {
          formatErrors.push(imeiString);
          return;
        }

        if (e instanceof ParseIMEILengthError) {
          lengthErrors.push(imeiString);
        }
      }
    });

  if (formatErrors.length || lengthErrors.length) {
    throw new StringToIMEIError(
      errorMessage,
      formatErrors,
      lengthErrors,
      IMEIs,
    );
  }

  return IMEIs;
}
